import ApiService from "./ApiService"

export async function apiGetUsers (data) {

    return ApiService.fetchData({
        url: '/users/all',
        method: 'POST',
        data
    })

}

export async function apiGetUser (userId) {

    return ApiService.fetchData({
        url: '/users/' + userId,
        method: 'GET',
    })

}

export async function apiCreateUser (data) {

    return ApiService.fetchData({
        url: '/users',
        method: 'POST',
        data
    })
}

export async function apiUpdateUser (data) {

    return ApiService.fetchData({
        url: '/users',
        method: 'PUT',
        data
    })
}


export async function apiDectivateUser (data) {

    return ApiService.fetchData({
        url: '/users',
        method: 'DELETE',
        data
    })
}

export async function apiSearchUsers (data) {

    return ApiService.fetchData({
        url: '/users/search',
        method: 'POST',
        data
    })
}
