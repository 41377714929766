import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut } from 'services/AuthService'
import { apiGetUser } from 'services/UserService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { Amplify, Auth } from 'aws-amplify';


//Amplify is intercepting every url that has a query param "code", which makes integration callbacks not being called
//This is a workaround as Amplify will fix this issue in future releases.
//
const _handleAuthResponse = Auth._handleAuthResponse.bind(Auth)
Auth._handleAuthResponse = (url) => {
  const configuration = Auth.configure()
  if (!url.includes(configuration.oauth.redirectSignIn)) return
  return _handleAuthResponse(url)
}


Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_kPE4dgj4r',
    userPoolWebClientId: '5voie3ufrh2j5sh7ub895sg5ij',
    mandatorySignIn: false,
    oauth: {
      domain: 'dev-teneut-domain.auth.us-east-1.amazoncognito.com',
      scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: "https://develop.teneut.com/oauth/callback",
      redirectSignOut: "https://develop.teneut.com",
      responseType: "token",
    },
  }
});

function useAuth() {

    const dispatch = useDispatch()

    const navigate = useNavigate()

	const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async ({ Username, Password }) => {
        try {
			const resp = await apiSignIn({ Username, Password });

            if (resp.idToken) {
                const userPayload = resp.idToken.payload;
                const token = resp.idToken
                const userData = await apiGetUser(userPayload.sub);
                let userFromDb = {};
                if(userData.data){
                    userFromDb = userData.data.data
                }
                let userSettings = userFromDb.settings ? userFromDb.settings : {};

                const user = {
                     id: userPayload.sub,
                     userName: userPayload.given_name + ' ' + (userPayload.family_name || '') ,
                     email: userPayload.email,
                     tenantId: userPayload['custom:tenantId'],
                     tenantName: userPayload['custom:tenantName'] || userFromDb.tenantName,
                     isAdmin: userPayload['custom:isAdmin'] || false,
                     profilePic: userPayload['custom:profilePic'] !== '' || userSettings.profilePic,
                     profilePicId: userPayload['custom:profilePic'] || userSettings.profilePicId,
                     authority: ['admin', 'user'],
                     accountUserName: 'admin'
                 };
                dispatch(setUser(user));
                dispatch(onSignInSuccess(token))
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
                return {
                    status: 'success',
                    message: ''
                }
            }

		} catch (errors) {
			return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString()
            }
		}
    }

    const federatedSignIn = async (resp) => {
            try {
                if (resp.idToken) {
                    const userPayload = resp.idToken.payload;
                    const token = resp.idToken
                    const userData = await apiGetUser(userPayload.sub);
                    let userFromDb = {};
                    if(userData.data){
                        userFromDb = userData.data.data
                    }
                    let userSettings = userFromDb.settings ? userFromDb.settings : {};
                    const user = {
                         id: userPayload.sub,
                         userName: userPayload.given_name + ' ' + userPayload.family_name,
                         email: userPayload.email,
                         tenantId: userPayload['custom:tenantId'],
                         tenantName: userPayload['custom:tenantName'] || userFromDb.tenantName,
                         isAdmin: userPayload['custom:isAdmin'] || false,
                         profilePic: userPayload['custom:profilePic'] != '' || userSettings.profilePic,
                         profilePicId: userPayload['custom:profilePic'] || userSettings.profilePicId,
                         authority: ['admin', 'user'],
                         accountUserName: 'admin'
                     };
                    dispatch(setUser(user));
                    dispatch(onSignInSuccess(token))
                    const redirectUrl = query.get(REDIRECT_URL_KEY)
                    navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
                    return {
                        status: 'success',
                        message: ''
                    }
                }

    		} catch (errors) {
    			return {
                    status: 'failed',
                    message: errors?.response?.data?.message || errors.toString()
                }
    		}
        }

    const handleSignOut = async()  => {
		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState))
		await Auth.signOut();
		navigate(appConfig.unAuthenticatedEntryPath)
	}

    const signOut = async () => {
		try {
			const resp = await apiSignOut()
			handleSignOut()
		} catch (errors) {
			handleSignOut()
		}
	}
    
    return {
        authenticated: token && signedIn,
        signIn,
        federatedSignIn,
        signOut
    }
}

export default useAuth